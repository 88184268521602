// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-campus-huisarts-enschede-index-jsx": () => import("/opt/build/repo/src/pages/campus-huisarts-enschede/index.jsx" /* webpackChunkName: "component---src-pages-campus-huisarts-enschede-index-jsx" */),
  "component---src-pages-contact-contact-form-jsx": () => import("/opt/build/repo/src/pages/contact/ContactForm.jsx" /* webpackChunkName: "component---src-pages-contact-contact-form-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("/opt/build/repo/src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-demo-jsx": () => import("/opt/build/repo/src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-huisartspraktijk-richters-enschede-index-jsx": () => import("/opt/build/repo/src/pages/huisartspraktijk-richters-enschede/index.jsx" /* webpackChunkName: "component---src-pages-huisartspraktijk-richters-enschede-index-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-jsx": () => import("/opt/build/repo/src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-studentenarts-utrecht-index-jsx": () => import("/opt/build/repo/src/pages/studentenarts-utrecht/index.jsx" /* webpackChunkName: "component---src-pages-studentenarts-utrecht-index-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

